// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-addiction-js": () => import("./../../../src/pages/addiction.js" /* webpackChunkName: "component---src-pages-addiction-js" */),
  "component---src-pages-application-js": () => import("./../../../src/pages/application.js" /* webpackChunkName: "component---src-pages-application-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-fentanyl-js": () => import("./../../../src/pages/fentanyl.js" /* webpackChunkName: "component---src-pages-fentanyl-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-outdoor-jam-js": () => import("./../../../src/pages/outdoor-jam.js" /* webpackChunkName: "component---src-pages-outdoor-jam-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-scholarship-js": () => import("./../../../src/pages/scholarship.js" /* webpackChunkName: "component---src-pages-scholarship-js" */),
  "component---src-pages-story-js": () => import("./../../../src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */),
  "component---src-pages-what-js": () => import("./../../../src/pages/what.js" /* webpackChunkName: "component---src-pages-what-js" */)
}

